<template>
  <main class="page my-ip-page">
    <section class="section">
      <h1 class="title title-lg text-center">
        {{ $t('web.my_ip_address') }}
      </h1>
      <p class="text text-2xl color-main text-center mt-4">
        {{ $store.state.user.realIP }}
      </p>
      <p class="text text-md text-center mt-4">
        {{ $t('web.your_ip') }}
      </p>
    </section>
  </main>
</template>

<script>
export default {
  name: 'MyIP',
  mounted() {
    this.$store.dispatch('user/fetchRealIP')
  },
}
</script>

<style lang="scss" scoped>
@import 'index';
</style>
